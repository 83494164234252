import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import SVGIcon from "../../SVGIcon"
import Buttons from "../../button"
import FormBuilder from "../Form-Builder"
const Nominee = ({
  multiFormData,
  setMultiFormData,
  page,
  setPage,
  checkStatus,
  setCheckStatus,
  setEmptyFields,
}) => {
  const [success, setSuccess] = useState(false)
  const formdatafields = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      value:
        multiFormData.firstname != ""
          ? multiFormData.firstname
          : checkStatus
          ? multiFormData.referrer_name
          : "",
      errorMessage: "Please enter a name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      value:
        multiFormData.email != ""
          ? multiFormData.email
          : checkStatus
          ? multiFormData.referrer_email
          : "",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "linkedin_profile_link",
      type: "url",
      required: false,
      label: "Linkedin or website link",
      value: multiFormData.linkedin_profile_link ?? "",
      errorMessage: "Please enter a valid url",
    },
    {
      name: "message",
      type: "textarea",
      required: false,
      label: "Why are you nominating this speaker?",
      value: multiFormData.message ?? "",
      errorMessage: "Please enter a valid message",
    },
  ]
  useEffect(() => {
    setMultiFormData(() => ({
      ...multiFormData,
      firstname: checkStatus ? multiFormData["referrer_name"] : "",
      email: checkStatus ? multiFormData["referrer_email"] : "",
    }))
  }, [checkStatus])
  const handleAfterSubmit = (res, item) => {
    // console.log(res, item)
    setSuccess(true)
    setEmptyFields(true)
  }
  const nominateAgain = () => {
    setCheckStatus(false)
    setPage(0)
    setMultiFormData({
      firstname: "",
      email: "",
      referrer_name: "",
      referrer_email: "",
      linkedin_profile_link: "",
      message: "",
    })
  }
  return (
    <div>
      {!success ? (
        <div className="fadeInAnimation">
          <p className="form-heading mb30">ABOUT THE NOMINEE</p>
          <label className="check-box mb24 d-flex align-items-center">
            <input
              type="checkbox"
              onChange={e => {
                setCheckStatus(e.target.checked)
              }}
              checked={checkStatus}
            />{" "}
            I am nominating myself
          </label>
          <FormBuilder
            id="sops"
            formClassName="forms"
            errorClassName="error-message"
            inputErrorClassName="error-active"
            data={formdatafields}
            page={page}
            setPage={setPage}
            buttonText="SUBMIT"
            buttonClassName="primary white"
            IpStackData
            multiFormData={multiFormData}
            setMultiFormData={setMultiFormData}
            afterSubmit={handleAfterSubmit}
            endpoint={process.env.HUBSPOT_SUPERPOD_NOMINEE_ENDPOINT}
            url={process.env.HUBSPOT_SUPERPOD_NOMINEE_URL}
            formName="Superpod Nominee - Superops"
          />
          <div className="p12 mt50 d-flex justify-content-center">
            <p className="form-footer">
              We care about protecting your data. Here’s our{" "}
              <Link to="/privacy" className="underline">
                Privacy Policy.
              </Link>
            </p>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center nominate-sucess flex-column">
          <SVGIcon name="nominateSuccess" />
          <h4 className="appreciation">Thanks for the nomination!</h4>
          <div className="btn-wrapper">
            <Buttons
              theme={`primary white`}
              onClick={() => nominateAgain()}
              text={"NOMINATE AGAIN"}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Nominee
