import React, { useEffect } from "react"
import FormBuilder from "../Form-Builder"
const Nominator = ({
  multiFormData,
  setMultiFormData,
  page,
  setPage,
  emptyFields,
  setEmptyFields,
}) => {
  const formdatafields = [
    {
      name: "referrer_name",
      type: "text",
      label: "Name",
      value: multiFormData.referrer_name ?? "",
      errorMessage: "Please enter a name",
    },
    {
      name: "referrer_email",
      type: "email",
      label: "Email address",
      value: multiFormData.referrer_email ?? "",
      errorMessage: "Please enter a valid email",
    },
  ]
  useEffect(() => {
    for (var i in formdatafields) {
      if (formdatafields[i].value === "") {
        return setEmptyFields(true)
      }
    }
    return setEmptyFields(false)
  })
  return (
    <div className="fadeInAnimation">
      <p className="form-heading mb30">ABOUT YOU</p>
      <FormBuilder
        id="sops"
        formClassName="forms"
        errorClassName="error-message"
        inputErrorClassName="error-active"
        data={formdatafields}
        page={page}
        setPage={setPage}
        emptyFields={emptyFields}
        setEmptyFields={setEmptyFields}
        buttonText="NEXT"
        buttonClassName="primary white"
        multiFormData={multiFormData}
        setMultiFormData={setMultiFormData}
      />
    </div>
  )
}

export default Nominator
