import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import "../Form-builder.scss"
import Nominator from "./NominatorForm"
import Nominee from "./NomineeForm"

function Multiform() {
  const [page, setPage] = useState(0)
  const [checkStatus, setCheckStatus] = useState(false)
  const [emptyFields, setEmptyFields] = useState(false)

  const [multiFormData, setMultiFormData] = useState({
    firstname: "",
    email: "",
    referrer_name: "",
    referrer_email: "",
    linkedin_profile_link: "",
    message: "",
  })

  const componentList = [
    <Nominator
      multiFormData={multiFormData}
      setMultiFormData={setMultiFormData}
      page={page}
      setPage={setPage}
      emptyFields={emptyFields}
      setEmptyFields={setEmptyFields}
    />,
    <Nominee
      multiFormData={multiFormData}
      setMultiFormData={setMultiFormData}
      page={page}
      setPage={setPage}
      checkStatus={checkStatus}
      setCheckStatus={setCheckStatus}
      setEmptyFields={setEmptyFields}
    />,
  ]
  return (
    <Row className="multi-form justify-content-between prelative">
      <Col className="left" lg={5}>
        <div>
          <div className="altv3">
            <h1 className="heading">Tell us a little about our next speaker</h1>
          </div>
          <div className="mt60 d-flex index-parent">
            <div className="index-wrapper altv1 d-flex align-items-center">
              <div
                className={`circle d-flex align-items-center justify-content-center ${page ===
                  0 && "active"} ${emptyFields ? "pe-none" : "pe-auto"}`}
                onClick={() => setPage(0)}
              >
                <h1 className="fw-bolder m-0">1</h1>
              </div>
              <h5 className={`sub-heading highlight ${page === 0 && "active"}`}>
                about you
              </h5>
            </div>
            <div className={`${page === 1 && "active"} form-line`}></div>
            <div className="index-wrapper altv1 d-flex align-items-center">
              <div
                className={`circle d-flex align-items-center justify-content-center ${page ===
                  1 && "active"} ${emptyFields ? "pe-none" : "pe-auto"}`}
                onClick={() => setPage(1)}
              >
                <h1 className="fw-bolder m-0">2</h1>
              </div>
              <h5 className={`sub-heading highlight ${page === 1 && "active"}`}>
                about the nominee
              </h5>
            </div>
          </div>
        </div>
      </Col>

      <Col className="right align-self-end" lg={5}>
        {componentList[page]}
      </Col>
    </Row>
  )
}

export default Multiform
